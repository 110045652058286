<template>
  <div class="login">
    <div class="header">
      <span><label style='block'><van-icon name="friends-o" />合作人员</label><label>（{{servesName}}）</label></span>
    </div>
    <van-form @submit="insertuserinfo" class='register'>
      <label><van-icon name="user-circle-o" />姓名</label>
      <van-field
        v-model="username"
        name="username"
        placeholder="输入你的姓名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <label><van-icon name="phone-o" />手机号<van-icon name="star" color="red" size="10"/></label>
      <van-field
        v-model="phonenum"
        name="phonenum"
        placeholder="输入你的手机号"
        :rules="[{ validator:validatorphonenum,required: true, message: '请填写手机号' }]"
      />
      <!--<div >
        <div style="margin-left:10px;position:relative;z-index:9999999;width:92%" >
      <van-field
        v-model="nationcode"
        name="nationcode"
        placeholder="国家"
        :rules="[{ required: true, message: '请填写国家'}]"
        style="width:80px;block:inline;float:left;"
      />
      <nation-code  @formatedNumber="getPhoneNumber"></nation-code>
      <van-field
        v-model="phonenum"
        name="phonenum"
        placeholder="输入你的手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
        @blur="getstatus_phone"
      />
      </div>
      </div>-->
      <label><van-icon name="eye-o" />设置密码</label>
      <van-field
        v-model="password"
        name='password'
        type='password'
        placeholder="6位以上数字、字母或特殊字符"
        :rules="[{ validator:validatorpassword,required: true, message: '请输入密码' }]"
      />
      <label><van-icon name="eye-o" />再输入一次</label>
       <van-field
        v-model="repassword"
        name="repassword"
        type='password'
        placeholder="6位以上数字、字母或特殊字符"
        :rules="[{ validator:reconfigpassword,required: true, message: '请输入密码' }]"
      />
      <label><van-icon name="coupon-o" />选择区域</label>
      <van-row>
        <van-col span="8">
          <!--<van-field
            readonly
            clickable
            name="nation"
            :value="nationvalue"
            placeholder="选择国家或地区"
            @click="shownation = true"
          />
          <van-popup v-model:show="shownation" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="shownation = false"
            />
          </van-popup>-->
          <select-nation @ongetnation="GetNation"></select-nation>
        </van-col>
        <van-col span="16"  v-if="isarea">
          <van-field
            v-model="areaValue"
            is-link
            readonly
            placeholder="选择所在地区"
            @click="onShowarea"
            :rules="[{validator:validatorarea }]"
          />
          <van-popup v-model:show="showarea" round position="bottom">
            <van-area title="选择所在地区" :area-list="areaList" @confirm="onconfirmarea" @cancel="oncancelearea"/>
          </van-popup>
        </van-col>
      </van-row>

      <label><van-icon name="notes-o" />身份证号<van-icon name="star" color="red" size="10"/></label>
      <van-field
        v-model="idcard"
        name="idcard"
        placeholder="请输入身份证号"
        :rules="[{validator:validatorIdcardMessage,required: true, message: '请输入身份证号' }]"
      />
      <div style="margin: 16px;">
        <van-button :disabled='isdisable' :loading='isloading' round block type="primary" native-type="submit" loading-type="rect">立即注册</van-button>
      </div>
     </van-form>
  </div>
</template>

<script>
import SelectNation from '@/components/SelectNation.vue';
import { areaList } from '@vant/area-data';
import axios from 'axios'
  
axios.defaults.timeout = 30 * 1000; // 30
const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    SelectNation
  },
  data() {
    return {
      servesName:'',
      serversPhone:'',
      username:'',
      nationname:'中国 (China)',
      phonenum:'',
      password:'',
      repassword:'',
      verifynum:'',
      idcard:'',
      isarea:true,
      isdisable:false,
      isloading:false,
      province:'',
      city:'',
      country:'',
      showarea: false,
      areaValue: '',
      areaList:areaList
    };
  },
  mounted(){
    this.get_invite_data()
  },
  
  //注册数据
  methods: {
    
    /*getPhoneNumber(number) {
      console.log("number", number)
      let phoneNumberInfo = parsePhoneNumber(number);
      this.phonenum = phoneNumberInfo.nationalNumber;
      this.nationcode = phoneNumberInfo.countryCallingCode
      this.country =phoneNumberInfo.country
      console.log(this.phonenum,this.nationcode,this.country)
    },*/
    
    //获取邀请人数据
    get_invite_data(){
      var query=this.$route.query;
      this.servesName=query.invite_user
      this.serversPhone=query.invite_phone
      console.log(this.serversPhone)
    },

    //检查手机是否注册
    async getstatus_phone(){
      let name=this.phonenum;
      await axios.post(base_url+'/user/checkUserAccount',
      {
        userLoginId:name
      }).then(res=>{
        if (res.data.code!=0){
        this.phonenum=''
        this.$toast('输入手机号已注册，请查实！');
        return
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //检查手机号位数
    validatorphonenum(){
       if (this.nationname==='中国 (China)' && this.phonenum.length<11){
          return "手机号位数小于11位，如果为其它国家或地区，请在下面区域先选择其它国家或地区。"
       }
    },

    validatorpassword(){
      if(this.password.length < 6 ){
        return "请输入6位以上密码！"
      }
      if(this.password==='123456'){
        return "密码请不要设置为："+this.password
      }
    },
      
    reconfigpassword(){
      if(this.password !== this.repassword){
        return "两次密码不一致，重新输入！"
      }
    },
    //判断是否填写区域
    validatorarea(){
      if (this.nationname==='中国 (China)' && this.areaValue===''){
        return "请选择所在区域！"
      }
    },

    //发送短信验证码
    sendverifycoode(){
      let params = {data: {}}
      params={userPhoneNum:this.phonenum}
      axios.post(base_url+'/authCode/getAuthCode',params)
      .then(res=>{
        console.log(res)
        this.$toast("短信验证码已发送！")
      })
    },
    //发送验证码
    checkverifycoode(){
      let params = {data: {}}
      params={
        userPhoneNum:this.phonenum,
        authCode:this.verifynum
      }
      axios.post(base_url+'/authCode/checkAuthCode',params)
      .then(res=>{
        console.log(res)
        this.$toast("验证成功！")
      })
    },
    
    //获取国家名字
    GetNation(value){
      this.nationname=value;
      if (this.nationname==='中国 (China)'){
        this.isarea=true;
      }else{
        this.isarea=false;
        this.oncancelearea();
      }
      console.log(this.nationname)
    },
    //判断是否选择区域
    onShowarea(){
      if (this.nationname==='中国 (China)'){
        this.showarea=true;
      }else{
        this.oncancelearea();
        this.$toast("非中国大陆地区不用选择所在区域！");
      }
    },

    //选择区域
    onconfirmarea(value){
      this.areaValue = value
        .filter(item => !!item)
        .map(item => item.name)
        .join("/");
      this.province=value[0].name;
      this.city=value[1].name;
      this.country=value[2].name;
      this.showarea=false;
    },
    oncancelearea(){
      this.areaValue='';
      this.province='';
      this.city='';
      this.country='';
      this.showarea = false;
    },
    
    //检验身份证格式，非中国大陆用户不验证身份证格式
      validatorIdcardMessage(){
      let idcardnum=this.idcard
      //const idcardReg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
      const idcardReg =  /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (this.nationname==='中国 (China)' && !idcardReg.test(idcardnum)) {
        return '身份证格式非法，请详细检查！'
      }
    },
    
    //注册用户
    onRegisterUser(values) {
      this.isdisable=true
      this.isloading=true
      alert(this.isdisable)
      let params = {data: {}}
      params={
         userPhoneNum:this.phonenum,
         authCode:this.verifynum,
      };
      
      axios.post(base_url+'/authCode/checkAuthCode',params)
      .then(res=>{
        console.log(res)
        this.insertuserinfo(values)
        return
      })
    },
    
    insertuserinfo(values){
      //this.getstatus_phone()

      this.isdisable=true
      this.isloading=true
      let params = {data: {}}
      params={
        userLoginId:this.phonenum,
        userName:values.username,
        userPhoneNum:this.phonenum,
        userLoginPw:values.password,
        userIdCard:values.idcard,
        userStatus:'1',
        userNation:this.nationname,
        userNationProvince:this.province,
        userNationDistrict:this.city,
        userNationCounty:this.country,
        userPartnerName:this.servesName,
        userPartnerPhoneNum:this.serversPhone,
        userJoinDatetime:this.getCurrentTime(),
        userPrivilege:3
      };
      console.log(params)
      axios.post(base_url+'/user/registerUser',params)
      .then(res=>{
        this.$toast(res.data.msg)
        if(res.data.code==200){
          this.$toast("请登录系统！")
          this.$router.push('/login');
          
          /*login(this.phonenum, values.password).then((result)=>{
          if(result.code >= 0){
            let data={
              id:result.data.id,
              name:result.data.name,
              phone:result.data.phone,
              level:result.data.level,
              token:result.data.token
            };
            userStore.setUserInfo(data)

            this.$router.push('/home');
          }
          })*/
        }else{
          this.isdisable=false
          this.isloading=false
        }
      });
    },


    //获取当前时间并打印
    getCurrentTime() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
      return _this.gettime
    }
  },
}
</script>

<style lang="less">
  .header{
    height: 100px;
    width: 100%;
    background: #1989fa;
    color:#fff;
    span{
      display: block;
      text-align: center;
      line-height: 100px;
    }
  }
  .register{
    background: #fff;
    display: block;
    padding: 15px 5% 20px 5%;

    label{
      display: block;
      margin:5px 0px 5px 7%;
    }
  }
  //:root{

    //--van-field-placeholder-text-color:#1989fa;
  //}
  .login {
    .login-body {
      padding: 0 20px;
    }
    .login {
      .link-register {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .register {
      .link-login {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .verify-bar-area {
      margin-top: 24px;
      .verify-left-bar {
        border-color: #1baeae;
      }
      .verify-move-block {
        background-color: #1baeae;
        color: #fff;
      }
    }
    .verify {
      >div {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cerify-code-panel {
        margin-top: 16px;
      }
      .verify-code {
        width: 40%!important;
        float: left!important;
      }
      .verify-code-area {
        float: left!important;
        width: 54%!important;
        margin-left: 14px!important;
        .varify-input-code {
          width: 90px;
          height: 38px!important;
          border: 1px solid #e9e9e9;
          padding-left: 10px;
          font-size: 16px;
        }
        .verify-change-area {
          line-height: 44px;
        }
      }
    }
  }
</style>
